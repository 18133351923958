const agent = window.navigator.userAgent.toLowerCase()

const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|Phone;\s+OpenHarmony/i.test(agent)

const isWxAppMiniProgram = /MicroMessenger\/.+ miniProgram/ig.test(agent)

// 是否在微信小程序中
const isInWxMiniProgram = isMobile && isWxAppMiniProgram

const isQQMiniProgram = /QQ\/.+ miniProgram/ig.test(agent)

// PC端微信小程序
const isPCWxMiniProgram = /MiniProgramEnv\/(Windows|Mac)/i.test(agent)

// 是否在QQ小程序中
const isInQQMiniProgram = isMobile && isQQMiniProgram


function isSafari(): boolean {
    return agent.includes("Safari") && !agent.includes("Chrome");
}

function getOS(): string {
    if (agent.includes("win")) {
        return "Windows";
    } else if (agent.includes("mac")) {
        return "macOS";
    } else if (agent.includes("linux")) {
        return "Linux";
    } else {
        return "Unknown";
    }
}


function getDeviceType(): string {

    if (/mobile/i.test(agent)) {
        return 'mobile';
    } else if (/ipad|tablet|playbook|silk/i.test(agent)) {
        return 'pad';
    } else {
        return 'pc';
    }
}

const userAgentUtils = { isSafari, getOS, getDeviceType };

export default userAgentUtils;