import userAgentUtils from '@/common/utils/userAgent';

const deviceType = userAgentUtils.getDeviceType();

if (deviceType === 'mobile') {
  import('./mobile/index').then(module => {
    module.init();
  });
} else {
  import('./pc/index').then(module => {
    module.init();
  });
}